@import '~app/mixins';

.gridOptions {
  position: relative;

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    padding: 10px 20px;
    display: none;
    z-index: 2;
    margin-top: 5px;
    width: 800px;
    top: 100%;
    right: -5px;
    color: $color-grey;

    @include primary-border-color();

    .sections {
      margin-bottom: 10px;

      .subSection {
        margin-top: 16px;
      }

      .title {
        padding-bottom: 8px;
        border-bottom: 2px solid #dadada;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .accordion {
        margin: 10px 0;
        border-bottom: 2px solid #dadada;
      }

      .items {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 10px 0 16px;

        .item {
          height: 21px;
          width: 24.5%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .layouts {
      margin-top: 15px;
      margin-bottom: 10px;

      .fields {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        > div {
          width: 49%;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      button {
        + button {
          margin-left: 10px;
        }
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      right: 10px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);
      background: $color-white;
      top: -3px;

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow+2;
      width: 15px;
      right: 5px;
      background: $color-white;
      top: 0;
    }
  }

  &.open {
    .dropdown {
      display: block;
    }

    .icon use {
      fill: $color-blue-darkest !important;
    }
  }
}
